import * as FS from './Footer.styles';
function Footer() {
    return (
        <>
        <FS.FooterSection className="footer">
            <div className="container pt-4">
                <div className="row mx-0 footer-padding">
                    <div className="col-12 col-lg-4 order-lg-first my-lg-auto mb-4">
                        <h4>Social</h4>
                        <p>
                            <a href="https://twitter.com/noai_industries">Twitter</a><br/>
                            <a href="https://instagram.com/noai_industries">Instagram</a><br/>
                        </p>
                    </div>
                    <div className="col-12 col-lg-4 ">
                        <h4>Contact</h4>
                        <p>
                            <a href="mailto:contact@noai.industries">Email Us</a><br/>
                        </p>
                    </div>
                    <div className="col-12">
                        <p className='text-center'>
                            <small>All images, content, and code created by Noai Industries, 2023</small>
                        </p>
                    </div>
                </div>
            </div>
        </FS.FooterSection>
        </>

    ) 
}

export default Footer;
