import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {Footer, Navbar} from './components';
import Home from './pages/Home/Home';
import GlobalStyles from './Globalstyles';
import React from 'react';

const AppContext = React.createContext(null);
export {AppContext};

function App() {


  const contextProvider = {

  }
  
  return (
    <Router>
      <AppContext.Provider value={contextProvider}>
        <GlobalStyles/>
        <Navbar/>
        <Switch>
          <Route exact path="/" component={Home}/>
        </Switch>
        <Footer/>

      </AppContext.Provider>
    </Router>
  );
}

export default App;