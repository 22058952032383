import styled, {createGlobalStyle} from 'styled-components';
import { Container } from 'react-bootstrap';
import * as bootstrapcss from 'bootstrap/dist/css/bootstrap.min.css';
import BAUHAUS from './fonts/BAUHS93.ttf';
import BebasNeue from './fonts/BebasNeue-Regular.otf';
const GlobalStyles = createGlobalStyle`
  html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}main{display:block}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}
  ${bootstrapcss}

    @font-face {
    font-family: 'BAUHAUS';
    src: local('BAUHAUS'), url(${BAUHAUS}) format('truetype');
    }
    @font-face {
      font-family: 'BebasNeue';
      src: local('BebasNeue'), url(${BebasNeue}) format('truetype');
      }
  

    .modal-title {
    font-family: Lato, Arial, serif;
    font-weight: black;
}
h1 {
  font-family: 'BAUHAUS', sans-serif;
  font-weight:900;
  letter-spacing:2px;
  background:#000;
  display:block;
  padding:10px 10px 6px 10px;
  line-height: 1.75;
}
  h2, h3, h4 {
    font-family: 'BebasNeue', sans-serif;
    font-weight:900;
    letter-spacing:2px;
    background:#000;
    display:block;
  }
  body {
    font-family: 'BebasNeue', sans-serif;
    font-weight:400;
    background:#000;
    color:white;
    overflow-x:hidden;
  }
  html{ 
    background:#000;
  }
  a {
    color:#f30a57;
    &:hover {
        color:white;
    }
  }

`;

export default GlobalStyles;

export const MainContainer = styled(Container)`

`;

export const Button = styled.button`
    transition: all .5s ease;

    &:hover{
        background-color: ${({primary}) => primary? '#fff': '#E38B06'};  
        transform: translateY(-.5rem) scale(1.02);
        color: #000;
    }
    &:active{
        transform: translateY(.5rem);
    }

    @media only screen and (max-width:1000px) {
        /* width: 100%; */
        padding: ${({big}) => big? '18px 30px' : '10px 20px'};
    }
    @media only screen and (max-width:375px) {
        padding: ${({big}) => big? '12px 20px' : '10px 20px'};
        font-size: ${({bigFont}) => bigFont? '16px': '18px'};
    }

`;
