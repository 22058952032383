import React from 'react';
import * as NS from './Navbar.styles';
import twittersvg from '../../images/twitter.svg';
import instagram from '../../images/instagram.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import logo from '../../images/logo.png';

const Navbar = () => {

    return (
        <NS.Nav id={"nav"} className={"navbar navbar-expand-md navbar-light sticky-top"}>
        <div className='container'>
            <NS.NavLogo>
            <LazyLoadImage effect="blur"
                className="img-fluid"
                src={logo} // use normal <img> attributes as props
            />
            </NS.NavLogo>
            <NS.MenuIcon>
                <a href={'https://twitter.com/noai_industries'}>
                    <img src={twittersvg} width={'30'} alt="Follow on Twitter" />
                </a>
                <a href={'https://instagram.com/noai_industries'}>
                    <img src={instagram} width={'30'} alt="Follow on Instagam" />
                </a>
            </NS.MenuIcon>
        </div>
        </NS.Nav>
    )
}

export default Navbar;
