import styled from "styled-components";
import { MainContainer } from "../../Globalstyles";


export const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 99999;
  height: 80px;
  background-color: rgba(0, 0, 0, 1);
  /* box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(sm) {
      display: block;
  }

  .brand {
    font-family: 'Edge', sans-serif;
    background:#f30a57;
    display:block;
    padding:5px 5px 1px 5px;
    letter-spacing:1px;
    font-size:28px;
    color:#fff;
    text-decoration:none;
  }

`;


export const NavbarContainer = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  ${MainContainer};

  .menu {
    margin: 0;
    display: flex;
    /* Works well with 100% width  */
    width: 15em;
    font-size: 1.5em;
    padding: 0 2.85em;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: var(--bgColorMenu);
  }

  .menu__item {
    all: unset;
    flex-grow: 1;
    z-index: 100;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    align-items: center;
    will-change: transform;
    justify-content: center;
    padding: 0.55em 0 0.85em;
    transition: transform var(--timeOut, var(--duration));
  }

  .menu__item::before {
    content: "";
    z-index: -1;
    width: 4.2em;
    height: 4.2em;
    border-radius: 50%;
    position: absolute;
    transform: scale(0);
    transition: background-color var(--duration), transform var(--duration);
  }

  .menu__item.active {
    transform: translate3d(0, -0.8em, 0);
  }

  .menu__item.active::before {
    transform: scale(1);
    background-color: var(--bgColorItem);
  }

  .icon {
    width: 2.6em;
    height: 2.6em;
    stroke: white;
    fill: transparent;
    stroke-width: 1pt;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 400;
  }

  .menu__item.active .icon {
    animation: strok 1.5s reverse;
  }

  @keyframes strok {
    100% {
      stroke-dashoffset: 400;
    }
  }
  .menu__border {
    left: 0;
    bottom: 99%;
    width: 10.9em;
    height: 2.4em;
    position: absolute;
    clip-path: url(#menu);
    will-change: transform;
    background-color: var(--bgColorMenu);
    transition: transform var(--timeOut, var(--duration));
  }

  .svg-container {
    width: 0;
    height: 0;
  }

  @media screen and (max-width: 50em) {
    .menu {
      font-size: 0.8em;
    }
  }
`;

export const NavLogo = styled.div`
  font-family: 'Edge', sans-serif;
  width:75px;
  display:block;
  padding:5px 5px 1px 5px;
  letter-spacing:1px;
  font-size:28px;
  color:#fff;
  text-decoration:none;
  cursor:default;
`;

export const NavIcon = styled.div`
  margin-right: 0.8rem;
`;

export const MenuIcon = styled.div`
  a {
    background-color:transparent;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    cursor:pointer;
    float:left;
    margin:0 5px;
  }
`;
