import * as HS from './Hero.styles';
import React, { useEffect, useState} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FadeIn from 'react-fade-in';

import hero1 from '../../images/hero/1.jpeg';
import hero2 from '../../images/hero/2.jpeg';
import hero3 from '../../images/hero/3.jpeg';
import hero4 from '../../images/hero/4.jpeg';
import hero5 from '../../images/hero/5.jpeg';
import hero6 from '../../images/hero/6.jpeg';
import hero7 from '../../images/hero/7.jpeg';
import hero8 from '../../images/hero/8.jpeg';
import hero9 from '../../images/hero/9.jpeg';
import hero10 from '../../images/hero/10.jpeg';
import hero11 from '../../images/hero/11.jpeg';
import hero12 from '../../images/hero/12.jpeg';

const Hero = () =>{

    useEffect(() => {
       
    }, [])
    const [key, setKey] = useState('art');
    return(
        <>
            <HS.TopContainer className='pb-4'>
                <div className='container'>
                    <div className='row'>
                    <h1>Noai</h1>
                    <FadeIn>
                        <h2>
                            Seattle / Worldwide
                        </h2>
                        <h3>
                            Fashion, Design, Technology, Manufacturing
                        </h3>
                    </FadeIn>
                    </div>
                </div>
            </HS.TopContainer>

            <HS.TabContainer className="col-lg-12">
                    <FadeIn>
                        <section className="col-lg-12">
                        <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlay
                                autoPlaySpeed={1800}
                                centerMode={true}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={true}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 2,
                                        slidesToSlide: 1, // optional, default to 1.
                                        partialVisibilityGutter: 0
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 1,
                                        slidesToSlide: 1 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 1,
                                        slidesToSlide: 1 // optional, default to 1.
                                    }
                                }}
                                rewind={true}
                                rewindWithAnimation={true}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero10} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero11} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={hero12} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                    </FadeIn>
            </HS.TabContainer>
        </>
    )
}

export default Hero;